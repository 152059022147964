import Column from '@/Components/Grid/Column';
import Container from '@/Components/Grid/Container';
import Row from '@/Components/Grid/Row';
import { PageProps } from '@/types';
import { Head, Link } from '@inertiajs/react';

import HeroImageMobile from '@static/splash-hero-mobile.png?w=1536;1024;767;375&format=webp&quality=85&as=srcset&imagetools';
import HeroImageWebp from '@static/splash-hero.png?w=3600;2560;1920;1500;991&format=webp&quality=85&as=srcset&imagetools';

import MobileScreensApp from '@static/mobile-screens-app.png?w=1536;1024;767;375&format=webp&quality=85&as=srcset&imagetools';

import PromotionPodcast from '@static/promotion-podcast.png?w=3000;1500;750;600;375&quality=85&as=srcset&format=webp&imagetools';

import Modal from '@/Components/Modal';
import AccordionItem from '@/Components/Pages/Splash/AccordionItem';
import VideoPlayer from '@/Components/VideoPlayer';
import resolveOptimizedImage from '@/Helpers/resolveOptimizedImage';
import useMediaQuery from '@/Hooks/useMediaQuery';
import { PodcastModel } from '@/types/models/PodcastModel';
import { VideoModel } from '@/types/models/VideoModel';

import MobileOff from '@static/mobile-off.png?quality=85&format=webp&imagetools';
import MobileOn from '@static/mobile-on.png?quality=85&format=webp&imagetools';
import PcOff from '@static/pc-off.png?quality=85&format=webp&imagetools';
import PcOn from '@static/pc-on.png?quality=85&format=webp&imagetools';
import TvOff from '@static/tv-off.png?format=webp&&quality=85&imagetools';
import TvOn from '@static/tv-on.png?quality=85&format=webp&imagetools';

import { useState } from 'react';

const Index = ({ auth, movies, podcasts }: PageProps & {
    movies: VideoModel[],
    podcasts: PodcastModel[];
}) => {
    const isMobile = useMediaQuery();

    const [isModalShown, setIsModalShown] = useState<boolean>(false);
    const [modalData, setModalData] = useState<any>('video');
    const [currentVideo, setCurrentVideo] = useState<VideoModel>();
    const [currentPodcast, setCurrentPodcast] = useState<PodcastModel>();

    const showModal = (type: string) => {
        setModalData(type);
        setIsModalShown(true);
    }

    const closeModal = () => {
        setIsModalShown(false);
        setTimeout(() => {
            setModalData(null);
        }, 300)
    }

    const showClickedVideo = (video: VideoModel) => {
        showModal('video');
        setCurrentVideo(video);
    }

    const showClickedPodcast = (podcast: PodcastModel) => {
        showModal('podcast');
        setCurrentPodcast(podcast);
    }

    return (
        <>
            <Head title="Strona główna" />

            <Modal show={isModalShown} onClose={closeModal}>
                {modalData == 'video' &&
                    <p className='text-center text-arcticMist h3 font-semibold tracking-[0.64px] mb-4'>{currentVideo?.name}</p>
                }
                {modalData == 'podcast' &&
                    <p className='text-center text-arcticMist h3 font-semibold tracking-[0.64px] mb-4'>{currentPodcast?.name}</p>
                }
                <p className='text-center text-arcticMist text-body tracking-[0.24px] mb-10'>Oglądasz krótki fragment materiału. Aby uzyskać pełen dostęp i korzystać z naszych treści, zapraszamy do rejestracji w Accord Academy.</p>
                <div className="w-full aspect-video player-wrapper relative mb-10 rounded-lg overflow-hidden [&>div>video]:object-cover maxMd:-mx-8 maxMd:w-[calc(100vw_-_32px)]">
                    <VideoPlayer
                        playing
                        controls
                        muted
                        url={modalData === 'video' ? currentVideo?.video : currentPodcast?.audio}
                        className='absolute inset-0 rounded-lg'
                        width={`100%`}
                        height={`100%`}
                    />
                </div>
                <p className='text-center text-arcticMist text-body tracking-[0.24px] mb-8'>
                    Zainteresował Cię ten materiał i chciałbyś kontynuować oglądanie? Dołącz do Accord Academy juź dziś. Rejestracja zajmie Ci maksymalnie <span className='text-accordorange'>15 minut.</span>
                </p>
                <div className='text-center'>
                    <Link href={route('register')} className='main-btn inline-block max-w-[176px]' title='Zarejestruj się'>
                        Zarejestruj się
                    </Link>
                </div>
            </Modal>

            <div className="min-h-screen bg-gradientMain relative flex flex-col justify-center">
                <div className='absolute top-0 w-full z-10 h-[52.3%] max-h-[630px] object-cover object-center bg-[url(@static/accord-symbol-pattern.svg)]'></div>

                <header className='maxMd:px-4 maxMd:pt-6 maxMd:pb-4 px-14 flex justify-between z-20 relative py-6 items-center maxMd:mb-6 mb-18'>
                    <Link href='/' title='Strona główna'>
                        <img src='/storage/static/accord-logo-full.svg' className='maxMd:max-w-[105px] max-w-40 w-full h-auto' />
                    </Link>
                    <div>
                        <Link href={route('login')} className='outline-btn border-accordorange border-1'>
                            Zaloguj się
                        </Link>
                    </div>
                </header>

                <main className='relative z-20'>
                    <Container>
                        <div className="flex flex-col">
                            <Row className='justify-center maxMd:hidden'>
                                <Column xl={2}>
                                    <img src="/storage/static/accord-logo.svg" alt="Accord Logo" width={67} height={56} className='mx-auto mb-6' />
                                </Column>
                            </Row>
                            <Row className='justify-center maxMd:order-2 maxMd:-mt-[35%] relative z-10'>
                                <Column xl={10}>
                                    <h1 className='text-arcticMist h1 font-semibold text-center mb-6'>Wiedza medyczna w zasięgu Twojej ręki, dostępna zawsze i wszędzie!</h1>
                                </Column>
                            </Row>
                            <Row className='justify-center maxMd:order-3'>
                                <Column xl={6} className='text-center'>
                                    <p className='text-arcticMist text-body mb-6'>
                                        Odkryj nowy wymiar dostępu do wiedzy medycznej z Accord Academy. Dostęp do najwyższej jakości materiałów edukacyjnych, gdziekolwiek i kiedykolwiek. Rozwijaj swoją wiedzę elastycznie i wygodnie. Dołącz do naszej społeczności i wykorzystaj potencjał Accord Academy
                                    </p>
                                    <Link href={route('register')} className='p-5 maxMd:py-3 maxMd:px-6 maxMd:w-full maxMd:mb-6 text-arcticMist bg-gradientButton rounded-lg text-h5 leading-8 font-semibold tracking-[0.36px] inline-block transition-theme hover:shadow-buttonHover hover:bg-gradientButtonHover'>
                                        Zarejestruj się
                                    </Link>
                                </Column>
                            </Row>
                            {!isMobile &&
                                <div className='pb-[50%] relative maxMd:mb-0 mb-38'>
                                    <img srcSet={HeroImageWebp} alt="Okładki hero" className='object-contain max-w-full w-full h-full absolute bottom-[12%] left-0 -z-10' />
                                </div>
                            }
                            {isMobile &&
                                <div className='relative maxMd:mb-0 mb-38 order-1'>
                                    <img srcSet={HeroImageMobile} alt="Okładki hero" className='' />
                                </div>
                            }
                        </div>
                        {/* <Row className='justify-center'>
                            <Column xl={6} className='text-center'>
                                <h2 className='text-arcticMist h2 font-semibold maxMd:mb-4 mb-6'>Ostatnio dodane materiały</h2>
                                <p className='text-arcticMist text-body maxMd:mb-8 mb-10'>Zobacz co możesz zyskać zapisując się do Accord Academy.</p>
                            </Column>
                        </Row>
                        <Row className='justify-center mb-30 maxMd:mb-16'>
                            <Column xl={10}>
                                <Row>
                                    {movies && movies.map((movie, i) => (
                                        <Column xl={4} md={6} lg={4} className='mb-8' key={i}>
                                            <button type='button' className='w-full text-left' onClick={() => showClickedVideo(movie)}>
                                                <div className="relative w-full pb-[56.25%] aspect-video">
                                                    <div className="absolute inset-0 bg-cover bg-no-repeat rounded-lg" style={{ backgroundImage: `url(${resolveOptimizedImage(movie.hero)})` }}></div>
                                                </div>
                                                <p className='text-arcticMist h4 font-semibold mt-2'>{movie.name}</p>
                                            </button>
                                        </Column>
                                    ))}
                                </Row>
                            </Column>
                        </Row> */}
                        <Row className='justify-center mb-38 maxMd:mb-24'>
                            <Column xl={10}>
                                <Row>
                                    <Column xl={6} lg={6} md={6} className='maxMd:order-2'>
                                        <img srcSet={PromotionPodcast} alt="Promocja podkast" />
                                    </Column>
                                    <Column xl={6} lg={6} md={6} className='self-center maxMd:order-1'>
                                        <div className="pl-4 pr-32 md:pr-16 maxMd:px-0">
                                            <h3 className='h2 text-arcticMist mb-6 font-semibold maxMd:text-center'>Oglądaj, słuchaj,<br /> czytaj.</h3>
                                            <p className='text-arcticMist text-body mb-4 maxMd:text-center'>W Accord Academy wiemy, że nie zawsze masz czas aby poświęcić nam 100% swojej uwagi. Dlatego udostępniamy Ci materiały w zróżnicowanej formie.</p>
                                            <ul className='text-arcticMist text-body mb-4 list-disc pl-4 maxMd:w-[55%] maxMd:mx-auto maxMd:mb-10'>
                                                <li>
                                                    Materiały wideo
                                                </li>
                                                <li>
                                                    Podcasty
                                                </li>
                                                <li>
                                                    Artykuły pisemne
                                                </li>
                                            </ul>
                                        </div>
                                    </Column>
                                </Row>
                            </Column>
                        </Row>
                        {/* <Row className='justify-center mb-34 maxMd:mb-24'>
                            <Column xl={8} className='text-center'>
                                <h4 className='h2 text-arcticMist font-semibold mb-6'>Podcasty dla specjalistów</h4>
                                <p className='text-arcticMist h4 mb-14 maxMd:mb-10'>Odkryj nasze podcasty i poszerz swoje horyzonty pozostawiając swobodne ręce.</p>
                            </Column>
                            <Column xl={10}>
                                <div className='flex gap-x-6 maxMd:gap-x-4 maxMd:gap-y-4 flex-wrap justify-center'>
                                    {podcasts && podcasts.map((podcast, i) => (
                                        <button type='button' onClick={() => showClickedPodcast(podcast)} className={`max-w-[240px] w-full ${(i === 0 || i === 1) ? 'maxMd:max-w-[45%]' : 'maxMd:max-w-[29%]'}`} key={i}>
                                            <img src={podcast.hero} alt={podcast.name} className='w-full aspect-square rounded-xl'/>
                                            <p className='text-arcticMist h4 maxMd:text-base maxMd:text-left tracking-[0.48px] font-semibold mt-2'>{podcast.name}</p>
                                        </button>
                                    ))}
                                </div>
                            </Column>
                        </Row> */}
                        <Row className='justify-center mb-44 maxMd:mb-24'>
                            <Column xl={8} className='text-center'>
                                <h5 className='h2 text-arcticMist font-semibold mb-6'>Dopasowany dla Twojej wygody</h5>
                                <p className='text-arcticMist text-desktop mb-14 maxMd:mb-10'>Accord Academy powstało dla Twojej wygody. Nasze materiały możesz oglądać gdziekolwiek i kiedykolwiek chcesz.</p>
                                {!isMobile &&
                                    <div className="flex md:flex-wrap sm:flex-wrap lg:flex-nowrap justify-center gap-y-10 gap-x-20">
                                        <div className="group w-1/3 h-full bg-cover bg-no-repeat relative">
                                            <div className="absolute inset-0 transition-theme opacity-0 group-hover:opacity-100">
                                                <img src={TvOn} alt="TV On" className="w-full h-[240px] object-contain" />
                                            </div>
                                            <img src={TvOff} alt="TV Off" className="w-full h-[240px] object-contain" />
                                            <p className='text-arcticMist h3 font-semibold mt-4'>TV</p>
                                        </div>
                                        <div className="group w-1/3 h-full bg-cover bg-no-repeat relative">
                                            <div className="absolute inset-0 transition-theme opacity-0 group-hover:opacity-100">
                                                <img src={PcOn} alt="TV On" className="w-full h-[240px] object-contain" />
                                            </div>
                                            <img src={PcOff} alt="TV Off" className="w-full h-[240px] object-contain" />
                                            <p className='text-arcticMist h3 font-semibold mt-4'>Komputer</p>
                                        </div>
                                        <div className="group w-1/3 h-full bg-cover bg-no-repeat relative">
                                            <div className="absolute inset-0 transition-theme opacity-0 group-hover:opacity-100">
                                                <img src={MobileOn} alt="TV On" className="w-full h-[240px] object-contain" />
                                            </div>
                                            <img src={MobileOff} alt="TV Off" className="w-full h-[240px] object-contain" />
                                            <p className='text-arcticMist h3 font-semibold mt-4'>Mobile</p>
                                        </div>
                                    </div>
                                }
                                {isMobile &&
                                    <img srcSet={MobileScreensApp} alt="Dostępne ekrany urządzeń" />
                                }
                            </Column>
                        </Row>
                    </Container>
                    <div className="bg-deepIndigo/70 py-24 maxMd:py-12">
                        <Container>
                            <Row className='justify-center'>
                                <Column xl={8} className='text-center'>
                                    <h6 className='h2 text-arcticMist font-semibold mb-14 maxMd:mb-10'>Najczęściej zadawane pytania</h6>
                                </Column>
                                <Column xl={8}>
                                    <AccordionItem heading='Czym jest Accord Academy?' content='Accord Academy to serwis edukacyjny zrzeszający lekarzy  z całego kraju. Serwis zawiera materiały edukacyjne w postaci artykułów bądz plików multimedialnych, co pozwala na szybki dostęp do treści medycznych dotyczących wybranych zagadnień. ' />
                                    <AccordionItem heading='Czy Accord Academy jest bezpłatne w całości?' content='Tak, serwis jest całkowicie bezpłatny.' />
                                    <AccordionItem heading='Jakie treści są publikowane w Accord Academy?' content='W serwisie AccordAcademy, publikujemy treści dostępne dla lekarzy w formie materiałów wideo, podcastów, artykułów do czytania oraz przeglądanie, udostępnionych produktów medycznych firmy Accord.' />
                                    <AccordionItem heading='Z czym wiąże się dołączenie do Accord Academy?' content='Dołączenie do Accord Academy to także dołączenie do społeczności lekarzy zaangażowanych w rozwój i poszerzanie kompetencji medycznych. Serwis oferuje nieograniczony dostęp do zamieszczanych treści, przez co interesujące Cię tematy możesz zgłębiać w dowolnym momencie.' />
                                    <AccordionItem heading='Jak mogę uzyskać pomoc w przypadku problemów?' content='W  serwisie AccordAcademy nigdy nie zostaniesz sam! W razie napotkania  problemów technicznych masz do dyspozycji Zespół ekspertów, którzy  pomogą Pani/Panu rozwiązać każdy problem związany z serwisem. Podczas  korzystania z serwisu, w stopce na dole strony widnieje zakładka Pomoc,  która wskaże Panu/Pani formy kontaktu z Działem Wsparcia.' />
                                </Column>
                            </Row>
                        </Container>
                    </div>
                </main>


                <footer className='px-14 maxMd:py-4 flex flex-wrap maxMd:gap-y-4 justify-center z-20 relative py-5 items-center gap-x-10 mt-auto'>
                    <a href='/polityka-prywatnosci.pdf' target='_blank' title='Polityka prywatności' className='text-royalPeriwinkle text-16 leading-6 tracking-[0.24px] maxMd:w-full maxMd:text-center'>
                        Polityka prywatności
                    </a>
                    <a href='/regulamin-serwisu.pdf' target='_blank' title='Regulamin serwisu' className='text-royalPeriwinkle text-16 leading-6 tracking-[0.24px] maxMd:w-full maxMd:text-center'>
                        Regulamin serwisu
                    </a>
                    <Link href={route('front.help')} title='Pomoc' className='text-royalPeriwinkle text-16 leading-6 tracking-[0.24px] maxMd:w-full maxMd:text-center'>
                        Pomoc
                    </Link>
                    <a target="_blank" href={`https://www.accord-healthcare.com/pl/`} title='Pomoc' className='md:w-auto w-full inline-block md:text-left text-center md:mt-0 mt-4 text-royalPeriwinkle text-body tracking-[0.24px] transition-theme hover:text-accordorange'>
                        Mój Accord
                    </a>
                </footer>
            </div>
        </>
    );
}

export default Index;